<template>
  <div id="user-management">

    <table-action-row
      :filterList="listQuery"
      @onFilterChange="value => { listQuery = value; getList() }"
      :handleAction="fullAccess ? () => goTo('/bo/user/detail') : null"
      actionButtonCode="ADD_USER"
      actionButtonIcon="icon-add"
    />

    <el-table
      v-loading="isLoading"
      class="newsily-bo-table"
      :data="list">
      <el-table-column
        :label="$t('NAME')"
        :width="200"
      >
        <template slot-scope="{row}">
          {{ getFullName(row) }}
        </template>
      </el-table-column>
      <el-table-column prop="email" :label="$t('EMAIL')" :width="isMobile ? 200 : null" />
      <el-table-column :label="$t('USER_ROLE')">
        <template slot-scope="{row}">
          {{ row.role | translate }}
        </template>
      </el-table-column>
      <el-table-column prop="availableCoins" :label="$t('COIN_BALANCE')" />
      <el-table-column prop="accumulatedCoins" :label="$t('COIN_ACCUMULATED')" :width="isMobile ? 100 : null" />
      <el-table-column :label="$t('REGISTERED_DATE')">
        <template slot-scope="{row}">
          {{ row.createdDate != null ? moment(row.createdDate).format(DATE_FORMAT) : null }}
        </template>
      </el-table-column>
      <el-table-column
        prop="status"
        :label="$t('STATUS')"
        width="100"
      >
        <template slot-scope="{row}">
          <div>
            <span :class="getStatusClass(row.status)">{{ $t(row.status) }}</span>
            <el-dropdown
              class="action-button"
              :hide-on-click="false"
              trigger="click"
            >
              <i class="custom-icon icon-edit mini center-in-div pointer"/>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item 
                  @click.native="goTo(`/bo/user/detail/${row.uuid}`)"
                >
                  {{ fullAccess ? $t("EDIT") : $t("VIEW") }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <pagination
      v-loading="isLoading"
      v-show="total > 0"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.limit"
      :total="total"
      @pagination="handleCurrentChange"
    />

  </div>
</template>

<script>
import { generalMixin } from '@/utils/general-mixin.js';
import TableActionRow from '@/components/table/table-action-row.vue'
import Pagination from '@/components/table/pagination.vue'
import cloneDeep from "lodash/cloneDeep"
import moment from 'moment'
import { PAGE_SIZES } from "@/constants";
import { getUserList } from "@/api/user"

const filterQuery = {
  page: 1,
  search: "",
  limit: PAGE_SIZES[0],
};

export default {
  name: "UserManagement",
  mixins: [generalMixin],
  components: {
    Pagination, TableActionRow
  },
  data() {
    return {
      listQuery: cloneDeep(filterQuery),
      showSearch: false,
      isLoading: false,
      total: 0,
      // Data
      list: [],
      moment,
    }
  },
  computed: {
    searchKeyword: {
      get() { return this.listQuery.search },
      set(value) {
        this.listQuery.search = value
      }
    }
  },
  methods: {
    // REDIRECTION
    goToEdit(id) {
      this.goTo(`/bo/user/detail/${id}`)
    },
    handleReset() {
      this.listQuery = cloneDeep(filterQuery)
      this.handleFilter();
    },
    toggleSearch() {
      this.showSearch = !this.showSearch
      if (this.showSearch == false) {
        this.searchKeyword = ""
        this.getList()
      }
    },
    handleCurrentChange(val) {
      this.listQuery.page = val.page
      this.listQuery.limit = val.limit
      this.getList()
    },
    getList() {
      // console.log('Sending: ', this.listQuery)
      this.isLoading = true
      getUserList(this.listQuery)
      .then(res => {
        // console.log('Got: ', res)
        if (res.status != 'ok') return
        this.list = cloneDeep(res.result.list)
        this.total = res.result.total

        // Back to previous page if empty data page
        const page = res.result.page
        const finalPage = res.result.finalPage
        if (finalPage < page) {
          this.listQuery.page = finalPage;
          this.getList();
        }

      })
      .catch(this.handleError)
      .finally(() => this.isLoading = false)
    },
  },
  mounted() {
    this.getList()
  }
}
</script>

<style lang="scss">
@import "@/assets/style/_variables.scss";
@import "@/assets/style/mixin/table.scss";
#user-management {
  position: relative;
  @include newsily-bo-table;
  .action-button {
    float: right;
  }
}
</style>